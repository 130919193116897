<template>
	<button @click="validateVisit"
		class="is-button is-button__basic--primary is-button--provider-website"
	>
		Visit website
	</button>
</template>

<script>
	export default {
		props     : [
			'provider','btnIdx'
		],
		mixins: [],
		components: {},
		methods: {
			validateVisit: function(e) {
				e.preventDefault();
				this.$emit('visit-website', {'provider':this.provider});
			},
		}
	}
</script>